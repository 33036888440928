<template>
  <div>
    <header-menu ref="HeaderMenu"></header-menu>
    <div class="view-content">
      <div class="l_r_space bgc_f4fbff subscribe text_left">
        <h1 class="subscribe_title font-size_40">预约演示</h1>
        <a-row :gutter="43">
          <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" :xxl="12">
            <div style="color: #666" class="font-size_16">
              <p>
                为了帮助你更好地了解和使用S2P综合平台系列产品，我们特为你提供了产品体验活动。你可以在此页面提交您的演示申请。申请成功后，将由我们专属服务人员为你安排预约服务。
              </p>
              <p>
                除了表单申请，你也可以直接拨打售前热线，获取更快的服务响应。
              </p>
            </div>
            <p class="subscribe_tel font-size_40">400-118-0106</p>
            <img
              src="@/assets/img/png/subscribe_page.png"
              alt=""
              style="width: 100%; max-width: 837px; margin-top: -0.5rem"
            />
          </a-col>
          <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" :xxl="12">
            <a-card class="card__box">
              <div slot="title" class="card__title">提交申请</div>
              <a-form :form="form">
                <a-form-item label="手机号">
                  <a-input
                    placeholder="请输入联系人手机号"
                    style="max-width: 640px"
                    type="number"
                    class="input_company"
                    v-decorator="[
                      'phone',
                      {
                        rules: [
                          { required: true, message: '请输入联系人手机号' },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
                <a-form-item label="企业名称">
                  <a-input
                    placeholder="请输入企业名称"
                    style="max-width: 640px"
                    class="input_company"
                    v-decorator="[
                      'company_name',
                      {
                        rules: [{ required: false, message: '请输入企业名称' }],
                      },
                    ]"
                  />
                </a-form-item>
                <a-form-item label="姓名">
                  <a-input
                    placeholder="请输入联系人姓名"
                    style="max-width: 640px"
                    class="input_company"
                    v-decorator="[
                      'realname',
                      {
                        rules: [
                          { required: false, message: '请输入联系人姓名' },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
                <a-form-item style="text-align: center">
                  <div class="update_img_header">
                    <span style="font-size: 14px; padding-right: 15px"
                      >上传名片</span
                    >
                    <span style="font-size: 12px; color: #999999"
                      >图片为jpg或png格式,大小不超过2m</span
                    >
                  </div>
                  <a-upload
                    class="update_img"
                    name="file"
                    v-decorator="[
                      'fileList',
                      {
                        rules: [{ required: false, message: '请上传名片' }],
                      },
                    ]"
                    :action="
                      this.$axios.defaults.baseURL +
                        'cms/official/Subscribe/upload'
                    "
                    list-type="picture-card"
                    :file-list="fileList"
                    :beforeUpload="beforeUpload"
                    :remove="removeImg"
                    @preview="handlePreview"
                    @change="handleChange"
                    :accept="'image/*'"
                  >
                    <div style="color: #b3cbf5" v-if="fileList.length < 1">
                      <a-icon type="plus" style="font-size: 40px" />
                      <div class="ant-upload-text">点击上传图片</div>
                    </div>
                  </a-upload>

                  <span style="font-size: 12px; color: #999999"
                    >提示：可以通过上传名片自动识别企业信息</span
                  >
                </a-form-item>
              </a-form>
              <div style="text-align: center; padding-top: 0.1rem">
                <a-button
                  shape="round"
                  type="primary"
                  style="width: 100%; height: 100%"
                  :size="'large'"
                  @click="_subMit"
                  :loading="btnLoading"
                >
                  <div style="padding: 8px 10px">提交申请</div>
                </a-button>
              </div>
            </a-card>
          </a-col>
        </a-row>
      </div>
    </div>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
    <Footer></Footer>
  </div>
</template>
<script>
import { getList, subMit } from '@/api';
import Footer from '@/components/Footer';
import HeaderMenu from '@/components/HeaderMenu';
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default {
  name: 'subscribe',
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  components: { Footer, HeaderMenu },
  data() {
    return {
      finished: false,
      previewVisible: false,
      previewImage: '',
      imgDisabled: false,
      fileList: [],
      provinceList: [],
      cityList: [],
      btnLoading: false,
    };
  },
  created() {
    this._getList();
  },
  methods: {
    removeImg() {
      console.log('1111');
      this.fileList = [];
      this.form.resetFields('fileList');
    },
    handleCancel() {
      this.previewVisible = false;
    },
    beforeUpload(file, fileList) {
      console.log('file', file);
      console.log('fileList', fileList);
      let type = file.type;
      if (type !== '' && !/^image\//.test(type)) {
        this.$message.warning('请选择图片格式的文件');
        this.fileList = [];
        this.form.resetFields('fileList');
        this.finished = false;
        return false;
      } else {
        let _arr = file.name.split('.');
        console.log('_arr[_arr.length - 1]', _arr[_arr.length - 1]);
        if (['png', 'jpg'].indexOf(_arr[_arr.length - 1]) == -1) {
          this.$message.warning('请选择jpg或png格式的图片');
          this.fileList = [];
          this.form.resetFields('fileList');
          this.finished = false;
          return false;
        } else {
          this.finished = true;
          return true;
        }
      }
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      console.log('fileList[0].response', fileList);
      if (fileList[0].status == 'done' && fileList[0].response.status) {
        this.finished = false;
        this.fileList = [];
        this.form.resetFields('fileList');
        this.$message.error(fileList[0].response.msg);
        return;
      }
      if (this.finished) {
        this.fileList = fileList;
        console.log('fileList', fileList);
        if (fileList.length >= 1) {
          this.imgDisabled = true;
        } else {
          this.imgDisabled = false;
        }

        if (
          fileList.length &&
          fileList[0].status &&
          fileList[0].status == 'done'
        ) {
          if (this.fileList[0].response.data.identifyResult) {
            let data = this.fileList[0].response.data.identifyResult.data;
            this.form.setFieldsValue({
              company_name: data.company_name,
              realname: data.realname,
              phone: parseInt(data.phone.replace(/\s*/g, '')),
            });
            this.$message.success(
              '自动识别成功，信息可能存在误差，请检查信息是否正确后再提交'
            );
          } else {
            this.$message.error(
              '自动识别失败，请自行填写企业信息或重新上传图片'
            );
          }
        }
        
      }
    },
    selectProvince(e) {
      this.form.resetFields('city_name');
      this.cityList = this.provinceList.find(
        (item) => item.region_name == e
      ).child;
    },
    _getList() {
      let parameter = {};
      getList(parameter).then((res) => {
        if (res.status === 0) {
          let data = res.data || [];
          this.provinceList = data;
        }
      });
    },
    _subMit() {
      this.form.validateFields((err, values) => {
        if (!err) {
            if (values.fileList) {
                delete values.fileList;
            }
//          if (!this.fileList.length) {
//            this.$message.error('请上传名片');
//            return;
//          }
//
        if (this.fileList.length){
                values.img_url = this.fileList[0].response.data.file_url;
          }
          this.btnLoading = true;
          subMit(values).then((res) => {
            if (res.status === 0) {
              this.$success({
                title: '提示',
                content: res.msg,
              });
              this.form.resetFields();
              this.fileList = [];
              this.btnLoading = false;
            } else {
              this.$message.error(res.msg);
              this.btnLoading = false;
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/assets/style/color.scss';
.subscribe {
  padding-bottom: 0.66rem;
  .subscribe_title {
    color: $color-lightBlue;
    padding: 0.9rem 0 0.5rem 0;
    margin-bottom: 0;
  }
  .subscribe_tel {
    color: $color-lightBlue;
  }
}
.form__item_m_h {
  min-height: 0.4rem;
}
.card__box {
  border-radius: 8px;
  box-shadow: #ddd 0px 0px 10px;
  .card__title {
    text-align: center;
  }
  .update_img {
    margin: 0 10%;
  }
  .input_company,
  .input_company:focus {
    border: none;
    background-color: transparent;
    border-bottom: 1px solid #eee !important;
    border-radius: 0;
  }
}
.update_img_header {
  text-align: left;
  padding-bottom: 20px;
}
.update_img_header::before {
  /*content: '*';*/
  /*color: red;*/
}
/deep/ .ant-upload.ant-upload-select-picture-card {
  width: 80%;
  background-color: #f4f9fe;
  height: 150px;
}
/deep/ .ant-upload-list-picture-card-container {
  width: 80%;
  height: 150px;
}
/deep/ .ant-upload-list-item {
  width: 100% !important;
  height: 100% !important;
}
/deep/ .ant-form-item {
  margin-bottom: 0;
}
/deep/ .ant-upload-list-picture-card .ant-upload-list-item-info::before {
  left: 0;
}
</style>
