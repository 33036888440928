var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header-menu',{ref:"HeaderMenu"}),_c('div',{staticClass:"view-content"},[_c('div',{staticClass:"l_r_space bgc_f4fbff subscribe text_left"},[_c('h1',{staticClass:"subscribe_title font-size_40"},[_vm._v("预约演示")]),_c('a-row',{attrs:{"gutter":43}},[_c('a-col',{attrs:{"xs":24,"sm":12,"md":12,"lg":12,"xl":12,"xxl":12}},[_c('div',{staticClass:"font-size_16",staticStyle:{"color":"#666"}},[_c('p',[_vm._v(" 为了帮助你更好地了解和使用S2P综合平台系列产品，我们特为你提供了产品体验活动。你可以在此页面提交您的演示申请。申请成功后，将由我们专属服务人员为你安排预约服务。 ")]),_c('p',[_vm._v(" 除了表单申请，你也可以直接拨打售前热线，获取更快的服务响应。 ")])]),_c('p',{staticClass:"subscribe_tel font-size_40"},[_vm._v("400-118-0106")]),_c('img',{staticStyle:{"width":"100%","max-width":"837px","margin-top":"-0.5rem"},attrs:{"src":require("@/assets/img/png/subscribe_page.png"),"alt":""}})]),_c('a-col',{attrs:{"xs":24,"sm":12,"md":12,"lg":12,"xl":12,"xxl":12}},[_c('a-card',{staticClass:"card__box"},[_c('div',{staticClass:"card__title",attrs:{"slot":"title"},slot:"title"},[_vm._v("提交申请")]),_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"手机号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'phone',
                    {
                      rules: [
                        { required: true, message: '请输入联系人手机号' } ],
                    } ]),expression:"[\n                    'phone',\n                    {\n                      rules: [\n                        { required: true, message: '请输入联系人手机号' },\n                      ],\n                    },\n                  ]"}],staticClass:"input_company",staticStyle:{"max-width":"640px"},attrs:{"placeholder":"请输入联系人手机号","type":"number"}})],1),_c('a-form-item',{attrs:{"label":"企业名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'company_name',
                    {
                      rules: [{ required: false, message: '请输入企业名称' }],
                    } ]),expression:"[\n                    'company_name',\n                    {\n                      rules: [{ required: false, message: '请输入企业名称' }],\n                    },\n                  ]"}],staticClass:"input_company",staticStyle:{"max-width":"640px"},attrs:{"placeholder":"请输入企业名称"}})],1),_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'realname',
                    {
                      rules: [
                        { required: false, message: '请输入联系人姓名' } ],
                    } ]),expression:"[\n                    'realname',\n                    {\n                      rules: [\n                        { required: false, message: '请输入联系人姓名' },\n                      ],\n                    },\n                  ]"}],staticClass:"input_company",staticStyle:{"max-width":"640px"},attrs:{"placeholder":"请输入联系人姓名"}})],1),_c('a-form-item',{staticStyle:{"text-align":"center"}},[_c('div',{staticClass:"update_img_header"},[_c('span',{staticStyle:{"font-size":"14px","padding-right":"15px"}},[_vm._v("上传名片")]),_c('span',{staticStyle:{"font-size":"12px","color":"#999999"}},[_vm._v("图片为jpg或png格式,大小不超过2m")])]),_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'fileList',
                    {
                      rules: [{ required: false, message: '请上传名片' }],
                    } ]),expression:"[\n                    'fileList',\n                    {\n                      rules: [{ required: false, message: '请上传名片' }],\n                    },\n                  ]"}],staticClass:"update_img",attrs:{"name":"file","action":this.$axios.defaults.baseURL +
                      'cms/official/Subscribe/upload',"list-type":"picture-card","file-list":_vm.fileList,"beforeUpload":_vm.beforeUpload,"remove":_vm.removeImg,"accept":'image/*'},on:{"preview":_vm.handlePreview,"change":_vm.handleChange}},[(_vm.fileList.length < 1)?_c('div',{staticStyle:{"color":"#b3cbf5"}},[_c('a-icon',{staticStyle:{"font-size":"40px"},attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("点击上传图片")])],1):_vm._e()]),_c('span',{staticStyle:{"font-size":"12px","color":"#999999"}},[_vm._v("提示：可以通过上传名片自动识别企业信息")])],1)],1),_c('div',{staticStyle:{"text-align":"center","padding-top":"0.1rem"}},[_c('a-button',{staticStyle:{"width":"100%","height":"100%"},attrs:{"shape":"round","type":"primary","size":'large',"loading":_vm.btnLoading},on:{"click":_vm._subMit}},[_c('div',{staticStyle:{"padding":"8px 10px"}},[_vm._v("提交申请")])])],1)],1)],1)],1)],1)]),_c('a-modal',{attrs:{"visible":_vm.previewVisible,"footer":null},on:{"cancel":_vm.handleCancel}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"alt":"example","src":_vm.previewImage}})]),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }